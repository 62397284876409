import { PageProps } from 'gatsby';
import { ProfileData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Profile';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import params from '~/params/profile.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useContact from '~/utils/useContact';

const PageEspaceProfiles: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
}) => {
  const { t } = useTranslation();
  const items = useContact({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          itemPathnamePrefix={`/espaces/${espace.id}/contacts/profiles/`}
          model={new ProfileData({ espace, params, espaceId: espace.id })}
          pathname={location.pathname}
          search={location.search}
          subMenu={items}
          title={t('contacts.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceProfiles);
